import React, { FunctionComponent, useCallback, useMemo, useState} from 'react';
import style from './style.module.css';



export type ProgressDotsProps = {
    stage: number,
    stages: number,
    style?: {
        stage: {
            diameter: number,
            complete: {
                borderColor: string, 
                backgroundColor: string,
            },
            incomplete: {
                borderColor: string, 
                backgroundColor: string,
            }
        }
    }
}

const ProgressDots:FunctionComponent<ProgressDotsProps> = (props) => {
    const progress = useMemo(() => {
        const arr:Array<{complete:boolean}> = [];
        for( let i = 1; i <= props.stages; i++ ) {
            arr.push({complete: i<=props.stage});
        }
        return arr;
    }, [props.stage, props.stages]);

    const styleDiameter:React.CSSProperties = useMemo(() => {
        return props.style? {
            height: props.style.stage.diameter-1,
            width: props.style.stage.diameter-1,
            borderRadius: Math.round(props.style.stage.diameter/2)
        } : {};
    }, [props.style]);
    const styleIncomplete:React.CSSProperties = useMemo(() => {
        return props.style? {
            ...props.style.stage.incomplete,
            ...styleDiameter
        } : {};
    }, [styleDiameter]);
    const styleComplete:React.CSSProperties = useMemo(() => {
        return props.style? {
            ...props.style.stage.complete,
            ...styleDiameter
        } : {};
    }, [styleDiameter]);

    return (
        <div className={style.root}>
            {progress.map((x, index) => (
                <div 
                    key={index}
                    className={style.stage} 
                    data-complete={x.complete? '1' : '0'}
                    style={x.complete? styleComplete : styleIncomplete}
                ></div>
            ))}
        </div>
    )
}

export default ProgressDots;