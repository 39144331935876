/*
Leaner than MUI, but not by much (+200kb). Apparently uses PropTypes - which might fail with Preact. 
*/


import React, { FunctionComponent, useState } from "react";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { DateTimePickerProps } from "../types";


type DatesChanged = (d1:Date | null, d2:Date | null) => boolean;
const datesChanged:DatesChanged = (d1, d2) => {
    if( !!d1 !== !!d2 ) return true;
    if( d1 && d2 && d1.valueOf()!==d2.valueOf() ) return true;
    return false;

}

const DTP:FunctionComponent<DateTimePickerProps> = (props) => {

    const [externalSelected, setExternalSelected] = useState(props.selected);
    const [selected, setSelected] = useState(props.selected);

    if( datesChanged(externalSelected, props.selected) ) {
        setExternalSelected(props.selected);
        setSelected(props.selected);
    }
    
    return (
        <DatePicker  
            showTimeSelect
            dateFormat="Pp" 
            
            selected={selected} 
            onChange={newDate => {
                console.log("onChange", {newDate})
                setSelected(newDate);
            }}
            onCalendarClose={() => {
                console.log("onCalendarClose", {selected,externalSelected })
                if( datesChanged(selected, externalSelected) ) {
                    console.log("onCalendarClose SUCCESS", {selected,externalSelected })
                    if( selected ) {
                        props.onChange(selected);
                    }
                }
            }}
        />
    )

}

export default DTP;
