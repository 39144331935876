import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState} from 'react';
import ProgressDots from '../ProgressDots';
import style from './style.module.css';



function visibleAttribute(stage:number, currentStage:number): '1' | '0' {
    return currentStage===stage? '1' : '0';
}

export type HelpStageProps = {
    children: ReactNode,
    currentStage: number,
    stage: number,
    stages: number,
    nextButtonText?: string
    next: () => void
}
const HelpStage:FunctionComponent<HelpStageProps> = (props) => {
    return (<div className={style.help_stage} data-visible={visibleAttribute(props.stage, props.currentStage)}>
        
        {props.children}

        <div className={style.help_commands}>
            <button onClick={e => props.next()}>{props.nextButtonText? props.nextButtonText : 'Next'}</button>
        </div>
        <div className={style.progress_container}>
            <ProgressDots stage={props.stage} stages={props.stages} />
        </div>
    </div>)
}



export type HelpStagesProps = {
    creatorName: string,
    onComplete: () => void
}
const HelpStages:FunctionComponent<HelpStagesProps> = (props) => {

    const [currentStage, setCurrentStage] = useState(1);

    const stages:Array<{body: JSX.Element, nextButtonText?: string}> = [
        {
            body: (<>
                <h2>Emails aren't <em>really</em> letters</h2>
                <div style={{fontSize:"30px"}}>🤔</div>
            </>)
        },
        {
            body: (<>
                <h2>They're <em>badly formatted tasks</em></h2>
                <p>
                    Because at the very least, they require you to read and reply
                </p>
            </>)
        },
        {
            body: (<>
                <h2>Your inbox doesn't help</h2>
                <p>
                    You have to try and <em>remember</em> what needs a reply. 
                </p>
                <p>
                    And {props.creatorName} is left wondering when to follow up. 
                </p>
            </>)
        },
        {
            body: (<>
                <h2>
                    Formz is an incredibly <span className={style.help_header_highlight}>easy</span> way to track email tasks <span className={style.help_header_highlight}>together</span>
                </h2>
                <ol>
                    <li>
                        <p>
                            Pick a time to get reminded
                        </p>
                    </li>
                    <li>
                        <p>
                            Now relax! 
                        </p>
                        
                        <p className={style.help_small}>
                            You'll get an email reminder then.
                        </p>
                        
                    </li>
                    <li>
                        <p>
                            {props.creatorName} will be informed
                        </p>
                        <p className={style.help_small}>
                            They won't be interrupting you with an annoying follow up.
                        </p>
                    </li>
                </ol>
            </>),
            nextButtonText: "One more thing..."
        },
        {
            body: (<>
                <h2>
                    There's nothing to install!
                </h2>
                <p>
                    It works entirely embedded within your emails. 
                </p>
            </>),
            nextButtonText: "Onward!"
        }
    ]

    return (<div className={style.root}>
        {stages.map((x, index) => (
            <HelpStage
            key={index}
            stage={index+1}
            stages={stages.length}
            currentStage={currentStage}
            nextButtonText={x.nextButtonText}
            next={() => {
                currentStage===stages.length? props.onComplete() : setCurrentStage(currentStage+1);
            }}
            >{x.body}</HelpStage>
        ))}
        
    </div>)
    
    
}


export default HelpStages;