import React, { createContext, useContext, useState, useEffect, FunctionComponent } from 'react';
import { SupabaseClient, createClient } from '@supabase/supabase-js';

const supabase = createClient('http://localhost:54321', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0')
const SupabaseContext = createContext<SupabaseClient>(supabase);

type SupabaseProviderProps = {
    children: React.ReactNode
}
export const SupabaseProvider:FunctionComponent<SupabaseProviderProps> = ({children}) => {

    /*
  const [supabase, setSupabase] = useState<SupabaseClient>();
  useEffect(() => {
    // Initialize your Supabase client
    const supabaseClient = createClient('http://localhost:54321', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0');
    setSupabase(supabaseClient);
  }, []);
  */

  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  );
};

export const useSupabase = () => useContext(SupabaseContext);
