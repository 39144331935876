
import React, { useCallback, useState } from 'react';
import {CreateChatCompletionResponse} from 'openai';
import styles from './App.module.css';


type CreateChatCompletionResponsePartial = Omit<CreateChatCompletionResponse, 'choices'> & {
    choices: Array<{
        delta: {
            content?: string
        },
        finish_reason: string | null,
        index: number
    }>
}

function isCreateChatCompletionResponsePartial(x: any): x is CreateChatCompletionResponsePartial {
    return (typeof x==='object') && x.object==='chat.completion.chunk';
}

export default function App() {

    const [text, setText] = useState<string>('');
    const [hobby, setHobby] = useState<string>(''); // HP
    const [loading, setLoading] = useState<boolean>(false);
    
    const [answer, setAnswer] = useState<string>('');

    const onSubmitText = useCallback(() => {

        setAnswer('');
        setLoading(true);
        //const evtSource = new EventSource("https://bespoke.formzhq.com/functions/v1/hello-world?query=${text}");
        const evtSource = new EventSource(`http://localhost:54321/functions/v1/aib?client_id=aib&role_description=${text}&hobby=${hobby}`);

        function finish() {
            setLoading(false);
            evtSource.close();
        }

        evtSource.addEventListener("message", (e: MessageEvent) => {
            console.log(e.data);

            if (e.data === '[DONE]') {
                finish();
                return
            }

            const completionResponse: CreateChatCompletionResponse | CreateChatCompletionResponsePartial = JSON.parse(e.data)
            if( isCreateChatCompletionResponsePartial(completionResponse) ) {
                //const text = completionResponse.choices[0]. //completionResponse.choices[0].text
                const text = completionResponse.choices[0].delta.content;
                if( text ) {

                    setAnswer((answer) => {

                        const currentAnswer = answer ?? ''

                        /*
                        
                        dispatchPromptData({
                            index: promptIndex,
                            answer: currentAnswer + text,
                        })
                        */

                        return currentAnswer + text
                    })
                }
            }
        });

        evtSource.addEventListener("error", (err) => {
            finish();
            console.error(err)
        })

    }, [text])

    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmitText();
        }
    }, [onSubmitText])

    return (
        <div className={styles.App}>
            <div>
                {answer}
            </div>
            <div>
                <div>
                    <label htmlFor='role_description'>Describe your role and pains in email</label>
                    <input id="role_description" type='text' onChange={e => setText(e.target.value)} value={text} />
                </div>
                <div className={styles.hobby} aria-hidden={true}>
                    <label htmlFor="hobby" >Leave this field blank</label>
                    <input id="hobby" className={styles.hobby} type='text' onChange={e => setHobby(e.target.value)} value={hobby} />
                </div>
                <button onClick={onSubmitText} onKeyUp={onKeyDown}>Submit</button>
            </div>
        </div>
    );
}


