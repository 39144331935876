import React, { FunctionComponent, useCallback, useMemo, useState} from 'react';
//import prettyTime from '../../utils/prettyTime';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import style from './style.module.css';
import DateTimePicker from '../DateTimePicker/react-datepicker';
//import {SERVERS} from '../../../../../src/config.json';
//import {RemindersPageProps} from '../../../../../functions/src/types';
import HelpStages from '../HelpStages';


// 079de78ed9eab762541fe8c5449f3b55df905d5b5254c4aeb17c64bc8e115a89
const SERVERS = {
    "LOCAL": "http://localhost:5001/formz-cc932/us-central1/app",
    "PRODUCTION": "https://formz-cc932.web.app"
};
export const SERVER = SERVERS['PRODUCTION'];

export type TaskForReminder = {
    taskId: string,
    createdAtMs: number | undefined,
    snoozeUntilMs: number | undefined,
    showPickingTime?:boolean,
    creatorName?:string,
    creatorAvatarUrl?:string,
    
}




dayjs.extend(relativeTime);

type RemindersPageProps = {
    task: TaskForReminder
};
const Reminders:FunctionComponent<RemindersPageProps> = (props) => {

    const [showFullHelp, setShowFullHelp] = useState(window.location.hash==='#helpfull');
    const [showHelp, setShowHelp] = useState(window.location.hash==='#help');
    const [showPickingTime, setShowPickingTime] = useState(window.location.hash==='#other');
    const [showPickingTimeUploading, setShowPickingTimeUploading] = useState(false);
    const [snoozeUntilMs, setSnoozeUntilMs] = useState(props.task.snoozeUntilMs);

    
    

    const [createdAtPretty, createdAtFullPretty] = useMemo(() => {
        const createdAt = dayjs(props.task.createdAtMs);
        const createdAtFullPretty = createdAt.format("h:mma, ddd D MMM YYYY");

        const now = dayjs();
        if( createdAt.year()!==now.year() ) {
            return [createdAt.format("D MMM YYYY"), createdAtFullPretty];
        } else if ( createdAt.date()===now.date() && createdAt.month()===now.month() ) {
            return [createdAt.format("h:mma"), createdAtFullPretty];
        } else {
            return [createdAt.format("D MMM"), createdAtFullPretty];
        }

    }, [props.task.createdAtMs])

    const [snoozeFromNowPretty, snoozeUntilPretty, snoozeUntilDate] = useMemo(() => {
        if( !snoozeUntilMs || snoozeUntilMs<Date.now() ) {
            return [null, null, null];
        }

        const snooze = dayjs(snoozeUntilMs);
        const now = dayjs();
        const tomorrow = now.add(1, 'day');

        let snoozeUntilPretty:string;
        if( snooze.year()!==now.year() ) {
            snoozeUntilPretty = snooze.format("h:mma, ddd D MMM YYYY");
        } else if ( snooze.date()===now.date() && snooze.month()===now.month() ) {
            snoozeUntilPretty = snooze.format("h:mma")+' today';
        } else if ( snooze.date()===tomorrow.date() && snooze.month()===tomorrow.month() ) {
            snoozeUntilPretty = snooze.format("h:mma")+' tomorrow';
        } else {
            snoozeUntilPretty = snooze.format("h:mma, ddd D MMM");
        }

        
        return [snooze.fromNow(),snoozeUntilPretty, new Date(snoozeUntilMs)];
        //return prettyTime(snoozeUntilMs-Date.now(), 'm');
    }, [snoozeUntilMs])

    const uploadRemindInMs = useCallback(async (remindInMs:number) => {
        
        const futureUntilMs = remindInMs>0? Date.now()+remindInMs : 0;
        setShowPickingTimeUploading(true);
        const result = await fetch(`${SERVER}/1.0/formz/remindInMs/${props.task.taskId}/${remindInMs}`, {
            method: 'POST'
        });
        let success = false;
        setShowPickingTimeUploading(false);
        if( result.ok ) {
            const json = await result.json();
            success = json.success;
        }
        if( success ) {
            setSnoozeUntilMs(futureUntilMs);
            setShowPickingTime(false);
        } else {
            alert("The reminder could not be set.");
        }
        
    }, []);

    const showPickingTimeFinal = useMemo(() => showPickingTime || !snoozeUntilDate, [showPickingTime, snoozeUntilDate]);

    const onClose = useCallback(() => {
        window.close();
    }, []);

    return (
        <div className={style.root}>
            {showFullHelp?
            (<HelpStages 
                creatorName={props.task.creatorName || ''}
                onComplete={() => setShowFullHelp(false)}
            />)
            :
            (<div>
                <div className={style.hero_pane}>
                    {/*<div className={style.brand} >Breef: Shared Reminders</div>*/}
                    <div className={style.backRow}>
                        <div className={style.back} onClick={onClose}>
                            <span className='material-icons'>arrow_back</span> 
                            Return to your email 
                            <span className={style.backEsc}>Esc</span>
                        </div>
                    </div>
                    {/*
                    <div className={style.title}>
                        <h2>
                            {snoozeUntilDate?
                            (<span>Thanks! We're now in sync 🎉</span>)
                            :
                            (<span>"Get a reminder about this task, and I'll know when to expect a reply"</span>)
                            }
                        </h2>
                    </div>
                    */}
                </div>
                <div className={style.people}>
                    <div className={style.person}>
                        {props.task.creatorAvatarUrl?
                        (<img className={`${style.personImage}`} src={props.task.creatorAvatarUrl} />)
                        :
                        (<span className={`${style.person} material-icons`}>person</span>)
                        }
                        
                        <div className={style.personName}>{props.task.creatorName}</div>
                    </div>
                </div>
                {/*
                <div className={style.bylineContainer}>
                    Let's track emails together...
                </div>
                */}
                
                <div className={style.sections}>
                    
                    
                    {/*showHelp?
                    (<div className={style.section}>
                        
                        <h3>
                            <span className='material-icons'>help</span>
                            <span>Help</span>
                            <span className={style.section_header_commands}>
                                <span className={style.command} onClick={() => setShowHelp(false)}>hide</span>
                            </span>
                        </h3>
                        <div className={style.help}>
                            <h4>The Problem</h4>
                            <p>
                                Emails aren't really letters, they're badly formatted tasks. 
                                
                            </p>
                            <p>
                                But your inbox isn't great at keeping up. So you feel a pressure to answer quickly, or else lose it forever. That's not healthy for your workflow. 
                            </p>
                            <h4>The Solution</h4>
                            <p>
                                {props.task.creatorName} realizes they're sending you tasks, and is trying to help.
                            </p>
                            <p>
                                You can confidently save the email for later, just like a Snooze feature.
                            </p>
                            <p>
                                But it also lets {props.task.creatorName} know when you're likely to reply, so they don't annoy you by following up. It lets you work effectively in sync. 
                            </p>
                            <h4>How it works</h4>
                            <ol>
                                <li>
                                    <p>Choose a reminder time for the email.</p>
                                </li>
                                <li>
                                    <p>{props.task.creatorName} will be informed.</p>
                                </li>
                                <li>
                                    <p>When the time comes, you'll receive an email reminder (in Gmail and Outlook this will move the original email to the top of your inbox).</p>
                                </li>
                            </ol>
                            
                        </div>
                    </div>)
                    : null*/}

                    <div className={style.section}>
                        
                                
                                {showPickingTimeUploading?
                                (
                                    <div>Saving...</div>
                                )
                                :
                                (
                                    showPickingTimeFinal?
                                    (<div className={style.remindTimePicker}>
                                        <h3 className={`${style.remindTimePickerTitle} ${style.remindTimePickerLine}`}>
                                            When would you like to be reminded?
                                        </h3>
                                        <div className={`${style.remindTimePickerByline} ${style.remindTimePickerLine}`}>
                                            This email will then be moved to the top of your inbox, and I'll feel in sync with you, knowing when you might reply.
                                        </div>
                                        <div className={style.remindTimePickerOptionList}>
                                            <div className={`${style.remindTimePickerOption} ${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>alarm</span> 
                                                <span onClick={e => uploadRemindInMs(1000*60*60)}>1 hour</span>
                                            </div>
                                            <div className={`${style.remindTimePickerOption} ${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>alarm</span> 
                                                <span onClick={e => uploadRemindInMs(1000*60*60*4)}>4 hours</span>
                                            </div>
                                            <div className={`${style.remindTimePickerOption} ${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>alarm</span> 
                                                <span onClick={e => uploadRemindInMs(1000*60*60*24*1)}>1 day</span>
                                            </div>
                                            <div className={`${style.remindTimePickerOption} ${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>alarm</span> 
                                                <span onClick={e => uploadRemindInMs(1000*60*60*24*3)}>3 days</span>
                                            </div>
                                            <div className={`${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>alarm</span> 
                                                <div className={style.remindTimePickerOtherContainer}>
                                                    <span className={style.remindTimePickerOtherTitle}>Or, pick a time:</span>
                                                    <DateTimePicker selected={snoozeUntilDate} onChange={(newDate) => {
                                                        if( newDate.valueOf()>Date.now() ) {
                                                            uploadRemindInMs(newDate.valueOf()-Date.now());
                                                        } else {
                                                            alert("Please pick a time in the future!");
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                            {snoozeUntilDate?
                                            (<div className={`${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}>
                                                <span className={`material-icons ${style.remindTimePickerLineGutter} ${style.remindTimePickerLineGutterOnlyHover}`}>cancel</span> 
                                                <span onClick={e => uploadRemindInMs(0)}>Cancel this reminder</span>
                                            </div>)
                                            : null }
                                        </div>
                                        {snoozeUntilDate?
                                        (<div 
                                            className={`${style.remindTimePickerGoBack} ${style.remindTimePickerLineClickable} ${style.remindTimePickerLine}`}
                                            onClick={() => setShowPickingTime(false)}
                                            >
                                            <span className={`material-icons ${style.remindTimePickerLineGutter}`}>arrow_back</span> 
                                            <span>back</span>
                                        </div>)
                                        : null }
                                    </div>)
                                    :
                                    
                                    (<div className={style.success}>
                                        
                                        <div>
                                            You've snoozed this email task <em>for both of us</em> until <strong>{snoozeUntilPretty}</strong>. Relax! There's no need to think about it until it reappears at the top of your inbox then. 
                                            {/*You've set a <em>shared</em> reminder to move this email to the top of your inbox at {snoozeUntilPretty}. Relax! There's no need to think about it until then.*/}
                                        </div>
                                        <div className={style.reminder_commands}>
                                            <div className={style.command} onClick={e => setShowPickingTime(true)}>Reschedule</div>
                                            <div className={style.command} onClick={e => uploadRemindInMs(0)}>Cancel Reminder</div>
                                        </div>
                                        
                                    </div>)
                                
                                )
                                }
                            
                    </div>


                    {!showPickingTimeFinal?
                    (
                    <div className={style.section}>
                        <div className={style.return_email_command_container}>
                            <button className={style.return_email_command} onClick={onClose}>
                                Return to your email (Esc)
                            </button>
                        </div>
                    </div>
                    ) : null
                    }

                    {!showHelp?
                    (
                    <div className={style.section}>
                        <div className={style.footer}>
                            <div className={`${style.command} ${style.command_help}`} onClick={e => setShowFullHelp(true)}>
                                <span className='material-icons'>help</span>
                                How does this work?
                            </div>
                        </div>
                    </div>
                    ) : null
                    }


                    
                    
                </div>
            </div>)
        }
        </div>
    )
};
export default Reminders;