import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './style.module.css';
import EditDocument from '../EditDocument';

type EditableDocumentRowProps = {
    id?: number,
    role?: string, 
    content?: string,
    onUpdate?: (changed?:boolean) => void
}
const EditableDocumentRow:FunctionComponent<EditableDocumentRowProps> = (props) => {

    

    const [role, setRole] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => setRole(props.role || ''), [props.role]);
    useEffect(() => setContent(props.content || ''), [props.content]);

    const onUpdate = useCallback((changed?:boolean) => {
        setEditing(false);
        if( props.onUpdate && changed ) props.onUpdate(changed);
    }, [props.onUpdate]);

    /**
     * On EditDocument save, cancel editing 
     * Add an 'edit' command that starts editing 
     * Add a cancel button while editing - maybe as part of Edit 
     */

    return (
        <tr className={styles.root}>
            {editing?
            (
                <td colSpan={3}>
                    <EditDocument role={role} content={content} id={props.id} isEditing={true} onUpdate={onUpdate} />
                </td>
            )
            :
            (
                <>
                    <td>
                        {role}
                    </td>
                    <td>
                        {content}
                    </td>
                    <td>
                        <button onClick={() => setEditing(true)}>Edit</button>
                    </td>
                </>
            )}
        </tr>
        )
}

export default EditableDocumentRow;