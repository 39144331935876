
import React, { useCallback, useEffect, useState } from 'react';
import { useLoaderData, LoaderFunction } from 'react-router';

import styles from './style.module.css';
import Reminders, { TaskForReminder, SERVER } from './components/Reminders';


type LoaderParams = {
    taskId: string;
}
function isLoaderParams(x: any): x is LoaderParams {
    return x && x.taskId;
}
export const loader:LoaderFunction = (data) => {
    if( !isLoaderParams(data.params) ) throw new Error("Require taskId: "+JSON.stringify(data));
    return data.params;
}

export default function Reminder() {

    const {taskId} = useLoaderData() as LoaderParams;

    const [task, setTask] = useState<TaskForReminder>();

    useEffect(() => {
        (async () => {
            const url = `${SERVER}/1.0/formz/getTaskForReminders`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ taskId })
                })
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const newTask: TaskForReminder = await response.json();
                setTask(newTask);
            } catch(e) {
                if( e instanceof Error ) {
                    console.warn(e.message + url);
                    throw e;
                }
            }
        })();
    }, [taskId])

    return (
        <div className={styles.root}>
            {task?
            (
                <Reminders task={task} />
            )
            : 
            (
                <>
                Loading...
                </>
            )}
        </div>
    );
}


