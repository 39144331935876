import React, { useCallback, useEffect, useState } from 'react';
import { createClient, PostgrestSingleResponse } from '@supabase/supabase-js'
import EditDocument from './components/EditDocument';
import { useSupabase } from '../../contexts/supabase';
import EditableDocumentRow from './components/EditableDocumentRow';




type VectorDocument = {
    id: number,
    role: string, 
    content: string
};


function isVectorDocument(x: any): x is VectorDocument {
    return typeof x==='object' && typeof x.content==='string';
}
function isVectorDocumentArray(x: any): x is VectorDocument[] {
    return Array.isArray(x) && x.every(isVectorDocument);
}


export default function Admin() {

    const supabase = useSupabase();

    const [documents, setDocuments] = useState<VectorDocument[]>([]);

    const refresh = useCallback(async () => {
        const response:PostgrestSingleResponse<VectorDocument[]> = await supabase.rpc('list_documents');
        
        if( isVectorDocumentArray(response.data) ) {
            console.debug("Retrieved documents: ", response.data);
            setDocuments(response.data);
        } else {
            // Assume error
            debugger;
        }
    }, []);
    
    useEffect(() => {
        refresh();
    }, []);
    

    return (
        <div>
            <table>
                <tbody>
                    {documents.map(document => (
                        <EditableDocumentRow key={document.id} {...document} onUpdate={(changed) => changed? refresh() : null} />
                    ))}
                </tbody>
            </table>

            <div>
                <EditDocument 
                onUpdate={() => refresh()}
                />
            </div>
        </div>
    )
}