import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './style.module.css';
import { useSupabase } from '../../../../contexts/supabase';


type EditDocumentProps = {
    id?: number,
    role?: string, 
    content?: string,
    isEditing?: boolean,
    onUpdate?: (changed?:boolean) => void
}
const EditDocument:FunctionComponent<EditDocumentProps> = (props) => {

    const supabase = useSupabase();

    const [role, setRole] = useState<string>('');
    const [content, setContent] = useState<string>('');

    useEffect(() => setRole(props.role || ''), [props.role]);
    useEffect(() => setContent(props.content || ''), [props.content]);

    const save = useCallback(() => {
        // Send straight to the server
        (async () => {

            
            const response = await supabase.functions.invoke<{embedding:number[]}>('save-document', {body: {role, content, id: props.id}});

            /*
            await supabase.rpc('upsert_document', {
                'p_role': role,
                'p_content': content,
                'p_id': props.id,
                'p_embedding': response.data?.embedding
            });
            */

            if( props.onUpdate ) props.onUpdate(true);

            if( !props.isEditing ) {
                setRole('');
                setContent('');
            }
        })()
    }, [supabase, props.id, role, content, props.isEditing, props.onUpdate]);

    const cancel = useCallback(() => {
        if( props.onUpdate ) props.onUpdate();
    }, []);

    return (<div className={styles.root}>
        <div className={styles.gridRow}>
            <label>Role:</label>
            <input type='text' value={role} onChange={(e) => setRole(e.target.value)}/>
        </div>
        <div className={styles.gridRow}>
            <label>Content:</label>
            <input type='text' value={content} onChange={(e) => setContent(e.target.value)}/>
        </div>
        <div className={styles.gridRow}>
            {props.isEditing?
            (
                <>
                <button onClick={save}>Save</button>
                <span onClick={cancel}>Cancel</span>
                </>
            )
            :
            (
                <button onClick={save}>Add</button>
            )
            }
        </div>
    </div>)
}

export default EditDocument;